.react-selectize {
  color: #000;
}
.react-selectize.root-node {
  position: relative;
  width: 300px;
}
.react-selectize.root-node.disabled {
  pointer-events: none;
}
.react-selectize.root-node .react-selectize-control {
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  position: relative;
  padding: 2px;
}
.react-selectize.root-node .react-selectize-control .react-selectize-placeholder {
  display: block;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  position: absolute;
  max-width: calc(100% - 56px);
}
.react-selectize.root-node .react-selectize-control .react-selectize-search-field-and-selected-values {
  display: flex;
  min-height: 30px;
  flex-grow: 1;
  flex-wrap: wrap;
}
.react-selectize.root-node .react-selectize-control .react-selectize-search-field-and-selected-values .resizable-input {
  background: none;
  border: none;
  outline: none;
  font-size: 1em;
  margin: 2px;
  padding: 4px 0px;
  vertical-align: middle;
  width: 0px;
}
.react-selectize.root-node .react-selectize-control .react-selectize-search-field-and-selected-values .value-wrapper {
  display: flex;
  align-items: center;
}
.react-selectize.root-node .react-selectize-control .react-selectize-reset-button-container,
.react-selectize.root-node .react-selectize-control .react-selectize-toggle-button-container {
  flex-grow: 0;
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}
.react-selectize.root-node .react-selectize-control .react-selectize-reset-button-container {
  width: 16px;
}
.react-selectize.root-node .react-selectize-control .react-selectize-toggle-button-container {
  width: 32px;
}
.react-selectize.root-node .react-selectize-control .react-selectize-reset-button-container:hover .react-selectize-reset-button path {
  stroke: #c0392b;
}
.react-selectize.root-node .react-selectize-control .react-selectize-reset-button path {
  transition: stroke 0.5s 0s ease;
  stroke: #999;
  stroke-linecap: square;
  stroke-linejoin: mitter;
}
.react-selectize.root-node .react-selectize-control .react-selectize-toggle-button path {
  fill: #999;
}
.react-selectize.dropdown-menu-wrapper {
  position: absolute;
}
.react-selectize.dropdown-menu-wrapper.tethered {
  min-width: 300px;
}
.react-selectize.dropdown-menu-wrapper:not(.tethered) {
  width: 100%;
}
.react-selectize.dropdown-menu {
  box-sizing: border-box;
  overflow: auto;
  position: absolute;
  max-height: 200px;
  z-index: 10;
}
.react-selectize.dropdown-menu.tethered {
  min-width: 300px;
}
.react-selectize.dropdown-menu:not(.tethered) {
  width: 100%;
}
.react-selectize.dropdown-menu .groups.as-columns {
  display: flex;
}
.react-selectize.dropdown-menu .groups.as-columns > div {
  flex: 1;
}
.react-selectize.dropdown-menu .option-wrapper {
  cursor: pointer;
  outline: none;
}
.multi-select.react-selectize.root-node .simple-value {
  display: inline-block;
  margin: 2px;
  vertical-align: middle;
}
.multi-select.react-selectize.root-node .simple-value span {
  display: inline-block;
  padding: 2px 5px 4px;
  vertical-align: center;
}
.simple-select.react-selectize.root-node .simple-value {
  margin: 2px;
}
.simple-select.react-selectize.root-node .simple-value span {
  display: inline-block;
  vertical-align: center;
}
.react-selectize.default {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}
.react-selectize.default.root-node .react-selectize-control {
  background-color: #fff;
  border: 1px solid;
  border-color: #d9d9d9 #ccc #b3b3b3;
  border-radius: 4px;
  font-size: 1em;
}
.react-selectize.default.root-node .react-selectize-control .react-selectize-placeholder {
  color: #aaa;
  text-indent: 8px;
}
.react-selectize.default.root-node .react-selectize-control .react-selectize-search-field-and-selected-values {
  padding-left: 5px;
}
.react-selectize.default.root-node.open.flipped .react-selectize-control {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.react-selectize.default.root-node.open:not(.flipped) .react-selectize-control {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.react-selectize.dropdown-menu-wrapper.default {
  overflow: hidden;
}
.react-selectize.dropdown-menu-wrapper.default .dropdown-menu.custom-enter-active,
.react-selectize.dropdown-menu-wrapper.default .dropdown-menu.custom-leave-active {
  transition: transform 0.2s 0s ease;
}
.react-selectize.dropdown-menu-wrapper.default .dropdown-menu.flipped.custom-enter {
  transform: translateY(100%);
}
.react-selectize.dropdown-menu-wrapper.default .dropdown-menu.flipped.custom-enter-active {
  transform: translateY(0%);
}
.react-selectize.dropdown-menu-wrapper.default .dropdown-menu.flipped.custom-leave {
  transform: translateY(0%);
}
.react-selectize.dropdown-menu-wrapper.default .dropdown-menu.flipped.custom-leave-active {
  transform: translateY(100%);
}
.react-selectize.dropdown-menu-wrapper.default .dropdown-menu:not(.flipped).custom-enter {
  transform: translateY(-100%);
}
.react-selectize.dropdown-menu-wrapper.default .dropdown-menu:not(.flipped).custom-enter-active {
  transform: translateY(0%);
}
.react-selectize.dropdown-menu-wrapper.default .dropdown-menu:not(.flipped).custom-leave {
  transform: translateY(0%);
}
.react-selectize.dropdown-menu-wrapper.default .dropdown-menu:not(.flipped).custom-leave-active {
  transform: translateY(-100%);
}
.react-selectize.dropdown-menu.default {
  background: #fff;
  border: 1px solid #ccc;
  margin-top: -1px;
}
.react-selectize.dropdown-menu.default.flipped {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.react-selectize.dropdown-menu.default:not(.flipped) {
  border-color: #b3b3b3 #ccc #d9d9d9;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.react-selectize.dropdown-menu.default .no-results-found {
  color: #aaa !important;
  font-style: oblique;
  padding: 8px 10px;
}
.react-selectize.dropdown-menu.default .simple-group-title {
  background-color: #fafafa;
  padding: 8px 8px;
}
.react-selectize.dropdown-menu.default .option-wrapper.highlight {
  background: #f2f9fc;
  color: #333;
}
.react-selectize.dropdown-menu.default .option-wrapper .simple-option {
  color: #666;
  cursor: pointer;
  padding: 8px 10px;
}
.react-selectize.dropdown-menu.default .option-wrapper .simple-option.not-selectable {
  background-color: #f8f8f8;
  color: #999;
  cursor: default;
  font-style: oblique;
  text-shadow: 0px 1px 0px #fff;
}
.multi-select.react-selectize.default.root-node .simple-value {
  background: #f2f9fc;
  border: 1px solid #c9e6f2;
  border-radius: 2px;
  color: #08c;
}
.simple-select.react-selectize.default.root-node.open .react-selectize-control {
  background-color: #fff;
}
.simple-select.react-selectize.default.root-node:not(.open) .react-selectize-control {
  background-color: #f9f9f9;
  background-image: linear-gradient(to bottom, #fefefe, #f2f2f2);
}
.react-selectize.bootstrap3 {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}
.react-selectize.bootstrap3.root-node.open .react-selectize-control {
  background-color: #fff;
  border: 1px solid #66afe9;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(102,175,233,0.6);
}
.react-selectize.bootstrap3.root-node .react-selectize-control {
  border: 1px solid;
  border-color: #d9d9d9 #ccc #b3b3b3;
  border-radius: 4px;
  font-size: 1em;
}
.react-selectize.bootstrap3.root-node .react-selectize-control .react-selectize-placeholder {
  color: #aaa;
  text-indent: 8px;
}
.react-selectize.bootstrap3.root-node .react-selectize-control .react-selectize-search-field-and-selected-values {
  padding-left: 5px;
}
.react-selectize.bootstrap3.dropdown-menu-wrapper.flipped {
  margin-bottom: 5px;
}
.react-selectize.bootstrap3.dropdown-menu-wrapper:not(.flipped) {
  margin-top: 5px;
}
.react-selectize.bootstrap3.dropdown-menu-wrapper .dropdown-menu.custom-enter-active,
.react-selectize.bootstrap3.dropdown-menu-wrapper .dropdown-menu.custom-leave-active {
  transition: opacity 0.2s 0s ease;
}
.react-selectize.bootstrap3.dropdown-menu-wrapper .dropdown-menu.custom-enter {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.react-selectize.bootstrap3.dropdown-menu-wrapper .dropdown-menu.custom-enter-active {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.react-selectize.bootstrap3.dropdown-menu-wrapper .dropdown-menu.custom-leave {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.react-selectize.bootstrap3.dropdown-menu-wrapper .dropdown-menu.custom-leave-active {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.react-selectize.bootstrap3.dropdown-menu {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0,0,0,0.175);
}
.react-selectize.bootstrap3.dropdown-menu.flipped {
  margin-bottom: 5px;
}
.react-selectize.bootstrap3.dropdown-menu:not(.flipped) {
  margin-top: 5px;
}
.react-selectize.bootstrap3.dropdown-menu .no-results-found {
  color: #aaa !important;
  font-style: oblique;
  padding: 8px 10px;
}
.react-selectize.bootstrap3.dropdown-menu .groups:not(.as-columns) > div:not(:first-child) {
  border-top: 1px solid #e5e5e5;
  margin: 12px 0px 0px 0px;
}
.react-selectize.bootstrap3.dropdown-menu .simple-group-title {
  background-color: #fff;
  color: #999;
  padding: 8px 8px;
  text-shadow: 0px 1px 0px rgba(0,0,0,0.05);
}
.react-selectize.bootstrap3.dropdown-menu .option-wrapper.highlight {
  background: #428bca;
}
.react-selectize.bootstrap3.dropdown-menu .option-wrapper.highlight .simple-option {
  color: #fff;
}
.react-selectize.bootstrap3.dropdown-menu .option-wrapper .simple-option {
  color: #333;
  cursor: pointer;
  padding: 8px 10px;
}
.react-selectize.bootstrap3.dropdown-menu .option-wrapper .simple-option.not-selectable {
  background-color: #f8f8f8;
  color: #999;
  cursor: default;
  font-style: oblique;
  text-shadow: 0px 1px 0px #fff;
}
.multi-select.react-selectize.bootstrap3.root-node .simple-value {
  background: #efefef;
  border-radius: 4px;
  color: #333;
}
.react-selectize.material {
  font-family: Roboto, sans-serif;
}
.react-selectize.material.root-node.open .react-selectize-control:after {
  transform: scaleX(1);
}
.react-selectize.material.root-node .react-selectize-control {
  border-bottom: 1px solid rgba(0,0,0,0.3);
}
.react-selectize.material.root-node .react-selectize-control:after {
  background-color: #00bcd4;
  content: "";
  transform: scaleX(0);
  transition: transform 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  left: 0px;
  bottom: -1px;
  width: 100%;
  height: 2px;
}
.react-selectize.material.root-node .react-selectize-control .react-selectize-placeholder {
  color: rgba(0,0,0,0.3);
  text-indent: 4px;
}
.react-selectize.material.dropdown-menu-wrapper.flipped {
  margin-bottom: 8px;
}
.react-selectize.material.dropdown-menu-wrapper.flipped .dropdown-menu {
  transform-origin: 100% 100%;
}
.react-selectize.material.dropdown-menu-wrapper:not(.flipped) {
  margin-top: 8px;
}
.react-selectize.material.dropdown-menu-wrapper:not(.flipped) .dropdown-menu {
  transform-origin: 0% 0%;
}
.react-selectize.material.dropdown-menu-wrapper .dropdown-menu.custom-enter-active,
.react-selectize.material.dropdown-menu-wrapper .dropdown-menu.custom-leave-active {
  transition: transform 250ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 250ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.react-selectize.material.dropdown-menu-wrapper .dropdown-menu.custom-enter {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  transform: scale(0, 0);
}
.react-selectize.material.dropdown-menu-wrapper .dropdown-menu.custom-enter-active {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  transform: scale(1, 1);
}
.react-selectize.material.dropdown-menu-wrapper .dropdown-menu.custom-leave {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  transform: scale(1, 1);
}
.react-selectize.material.dropdown-menu-wrapper .dropdown-menu.custom-leave-active {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.react-selectize.material.dropdown-menu {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: rgba(0,0,0,0.118) 0px 1px 6px, rgba(0,0,0,0.118) 0px 1px 4px;
  max-height: 250px;
  padding: 8px 0px;
}
.react-selectize.material.dropdown-menu.flipped {
  margin-bottom: 8px;
}
.react-selectize.material.dropdown-menu:not(.flipped) {
  margin-top: 8px;
}
.react-selectize.material.dropdown-menu .no-results-found {
  font-style: oblique;
  font-size: 16px;
  height: 32px;
  padding: 0px 16px;
  display: flex;
  align-items: center;
}
.react-selectize.material.dropdown-menu .groups:not(.as-columns) > div:not(:last-child) {
  border-bottom: 1px solid #e5e5e5;
}
.react-selectize.material.dropdown-menu .simple-group-title {
  color: #8f8f8f;
  display: flex;
  align-items: center;
  font-size: 14px;
  height: 48px;
  padding: 0px 10px;
}
.react-selectize.material.dropdown-menu .option-wrapper.highlight {
  background-color: rgba(0,0,0,0.098);
}
.react-selectize.material.dropdown-menu .option-wrapper .simple-option {
  color: rgba(0,0,0,0.875);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 16px;
  height: 48px;
  padding: 0px 16px;
}
.react-selectize.material.dropdown-menu .option-wrapper .simple-option.not-selectable {
  background-color: #f8f8f8;
  color: #999;
  cursor: default;
  font-style: oblique;
  text-shadow: 0px 1px 0px #fff;
}
.multi-select.react-selectize.material.root-node .simple-value span {
  padding: 0px;
}
.multi-select.react-selectize.material.root-node .simple-value span:after {
  content: ",";
}
.simple-select.react-selectize.material.root-node .simple-value {
  margin: 4px 3px 3px 2px;
}
